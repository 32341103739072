.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: all 0.5s;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: all 0.5s;
}
